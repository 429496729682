import i18next from 'i18next';

import styles from './LanguageSelector.module.scss';

import { locales } from '#langConfig';
import { prepareUrl } from '#utils/prepareUrl';
import { LANGS, REVERSE_USERNAME_LOOKUP_LANGS, IP_TRACKER_LANGS } from '#config';
import { useMemo } from 'react';

type Props = {
  isLight: boolean;
};

const LanguageSelector = ({ isLight }: Props) => {
  const currentLanguage = i18next.language;

  const changeLanguage = (lng: string) => () => {
    if (typeof document === 'undefined') return '';
    i18next.changeLanguage(lng).then(() => (document.location.href = prepareUrl(null, lng)));
  };

  const getLocaleTitle = (lng: string) => {
    const languageTitle = locales.find((l) => l.locale.includes(lng));
    return languageTitle ? languageTitle.title : '';
  };

  const checkIsActive = (lng: string) => lng === currentLanguage;

  const handleActive = (active: boolean, className: string) =>
    `${className} ${active ? styles.active : ''}`;

  const langs = useMemo(() => {
    const path = document.location.href;
    if (path.includes('reverse-username-lookup')) return REVERSE_USERNAME_LOOKUP_LANGS;
    else if (path.includes('ip-tracker')) return IP_TRACKER_LANGS;
    else return LANGS;
  }, [document.location.href]);

  return (
    <ul className={`${styles.menuItems} ${isLight && styles.light}`}>
      {langs.map((lng: string) => (
        <li
          onClick={changeLanguage(lng)}
          className={handleActive(checkIsActive(lng), styles.menuItem)}
          key={`${lng}_lang`}
        >
          {getLocaleTitle(lng)}
        </li>
      ))}
    </ul>
  );
};

export default LanguageSelector;
